<template>
  <ContentWrapper
    v-if="(isEdit && formObject.id) || !isEdit"
    v-loading="loading"
  >
    <ValidationObserver v-slot="{ handleSubmit }">
      <el-form
        ref="form"
        label-position="top"
        :model="formObject"
      >
        <el-row :gutter="20">
          <el-col
            :span="24"
            class="title-wrapper"
          >
            <span class="request-title">{{ $t('offer.create.request') }}</span>
            <span class="request-subtitle">{{ $t('offer.create.requestSubtitle') }}</span>
          </el-col>
          <el-col :span="24">
            <FormItem
              rules="required"
              :label="$t('samples.create.sector')"
            >
              <el-select
                v-model="formObject.commodityCategory"
                placeholder=""
                clearable
              >
                <el-option
                  v-for="(option) in sectorsList"
                  :key="option.value"
                  :label="$t(`samples.sectors.${option.value}`)"
                  :value="option.value"
                />
              </el-select>
            </FormItem>
          </el-col>

          <el-col :span="24">
            <FormItem
              :label="$t('offer.request.offer')"
            >
              <el-select
                v-model="formObject.offerId"
                filterable
                clearable
                :loading="autocompleteLoading"
                remote
                placeholder="Select"
                :remote-method="findOffersByDocNumber"
              >
                <el-option
                  v-for="item in offers"
                  :key="item.id"
                  :label="item.docNumber"
                  :value="item.id"
                />
              </el-select>
            </FormItem>
          </el-col>

          <el-col :span="24">
            <FormItem :label="$t('offer.request.message')">
              <el-input
                v-model="formObject.text"
                type="textarea"
                resize="none"
                :autosize="{ minRows: 9, maxRows: 12}"
              />
            </FormItem>
          </el-col>

          <el-col :span="24">
            <FormItem
              :label="$t('offer.request.privacyConsentLabel')"
              rules="required"
            >
              <el-checkbox v-model="formObject.privacyConsent">
                {{ $t('offer.request.privacyConsentText') }}
              </el-checkbox>
            </FormItem>
          </el-col>

          <el-col :span="24">
            <FormItem label="">
              <el-checkbox
                v-model="formObject.marketingConsent"
                class="consent-checkbox"
              >
                <p>{{ $t('offer.request.marketingConsentText1') }}</p>
                <p>{{ $t('offer.request.marketingConsentText2') }}</p>
              </el-checkbox>
            </FormItem>
          </el-col>
        </el-row>

        <FormButtons>
          <el-button
            type="secondary"
            @click="$router.back()"
          >
            {{ $t('global.cancel') }}
          </el-button>
          <el-button
            type="primary"
            native-type="submit"
            @click.prevent="handleSubmit(createOfferRequest)"
          >
            {{ $t('global.continue') }}
          </el-button>
        </FormButtons>
      </el-form>
    </ValidationObserver>
  </ContentWrapper>
</template>

<script>
import FormItem from '@/components/FormItem.vue';
import ContentWrapper from '@/components/ContentWrapper.vue';
import FormButtons from '@/components/FormButtons.vue';
import { offersApi } from '../../api';

export default {
  name: 'RequestOffer',
  components: {
    FormItem,
    ContentWrapper,
    FormButtons,
  },
  data() {
    return {
      sectorsList: [
        { value: 'foodSupplement' },
        { value: 'other' },
        { value: 'chemical' },
        { value: 'cosmetic' },
        { value: 'detergents' },
        { value: 'medicalDevice' },
        { value: 'packaging' },
        { value: 'biocide' },
      ],
      offers: [],
      loading: false,
      autocompleteLoading: false,
      formObject: {
        offerId: '',
        commodityCategory: '',
        text: '',
        privacyConsent: null,
        marketingConsent: false,
      },
    };
  },
  computed: {
    isEdit() {
      return this.$route.params.id || false;
    },
    previousDocNumber() {
      return this.$route.query.docNumber || null;
    },
  },
  async mounted() {
    if (this.previousDocNumber) {
      await this.findOffersByDocNumber(this.previousDocNumber);
      if (this.offers.length === 1) {
        this.formObject.offerId = this.offers[0].id;
      } else {
        this.$notify({ type: 'error', message: this.$t('offer.request.previousOfferNotFind') });
      }
    }
  },
  methods: {
    async findOffersByDocNumber(name) {
      try {
        this.autocompleteLoading = true;
        const offers = await offersApi.findOffersByDocNumber(name);
        this.offers = offers.data;
        this.autocompleteLoading = false;
        return offers;
      } catch (error) {
        this.autocompleteLoading = false;
        return error;
        // throw error;
      }
    },
    async createOfferRequest() {
      try {
        this.loading = true;
        await offersApi.createOfferRequest(this.formObject);
        this.$message({ showClose: true, type: 'success', message: this.$t('offer.request.success') });
        this.loading = false;
        this.$router.push({ name: 'offersIndex' });
      } catch (error) {
        this.loading = false;
        this.$message({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
  },
};
</script>
<style lang="scss">
.title-wrapper {
  margin-bottom: 2rem;
  .request-title {
    font-size: 1.5rem;
    display: block;
  }
  .request-subtitle {
    font-size: 1rem;
    display: block;
    margin-top: 0.2rem;
  }
}
.consent-checkbox {
  display: flex;
}
.el-checkbox__label {
  white-space: break-spaces;
}
</style>
